<template>
  <a-button
    :class="buttonClasses"
    v-bind="buttonProps"
    :id="buttonId"
    @click="openPediaAssistant"
  >
    <slot v-if="hasSlot" />

    <template v-else>
      <span class="assistant__button-text-light">Looking for a service?</span>
      <span class="assistant__button-text-bold">We can help</span>
    </template>
  </a-button>
</template>

<script>
import { ASSISTANT_BUS_NAME } from '@/components/_layout/APediaAssistant/lazy'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { FIXED_LAYOUT } from 'enums/fixed-layout'

export default {
  name: 'AAssistantButton',
  props: {
    fixedLayout: propValidator(
      [PROP_TYPES.STRING],
      false,
      null,
      value => value == null || Object.values(FIXED_LAYOUT).includes(value)
    ),
    buttonId: propValidator([PROP_TYPES.STRING])
  },
  computed: {
    buttonProps() {
      return {
        ...(this.$helper.isAmpPage(this.$route.name)
          ? {
              href: this.$helper.removeAmpSuffix(this.$route.path),
              openInNewTab: false,
              removeDirective: true
            }
          : {})
      }
    },
    buttonClasses() {
      return [
        'assistant__button',
        {
          'assistant__button-responsive': !this.fixedLayout,
          'assistant__button-fixed-desktop':
            this.fixedLayout === FIXED_LAYOUT.DESKTOP,
          'assistant__button-fixed-mobile':
            this.fixedLayout === FIXED_LAYOUT.MOBILE
        }
      ]
    },
    hasSlot() {
      return !!this.$slots.default
    }
  },
  methods: {
    openPediaAssistant() {
      this.$bus.$emit(ASSISTANT_BUS_NAME)
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin assistant-button-mobile {
  height: 26px;
  background: $c--white;
  border: 1px solid $c--mobile-search-btn;
  border-radius: 6px;

  /deep/ .a-button__slot-wrapper {
    flex-direction: row;
  }

  & .assistant__button-text-light,
  .assistant__button-text-bold {
    font-size: 15px;
    color: $c--mobile-search-btn;
    line-height: 20px;
    margin-right: 3px;
  }
}

@mixin assistant-button-desktop {
  font-size: 16px;
  background: $c--main;
  color: $c--white;

  .assistant__button-text-light,
  .assistant__button-text-bold {
    font-size: 16px;
    line-height: 20px;
  }

  .assistant__button-text-light {
    font-weight: 100;
  }

  .assistant__button-text-bold {
    font-weight: bold;
  }
}

.assistant__button.assistant__button-fixed-mobile {
  @include assistant-button-mobile;
}

.assistant__button.assistant__button-fixed-desktop {
  @include assistant-button-desktop;
}

.assistant__button.assistant__button-responsive {
  @include assistant-button-desktop;

  @include mobile {
    @include assistant-button-mobile;
  }
}
</style>
