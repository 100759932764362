<template>
  <div class="footer-service__wrapper">
    <p class="footer-service__title">Looking for a Service?</p>
    <a-assistant-button
      class="footer-service__button"
      :fixed-layout="$options.consts.FIXED_LAYOUT.DESKTOP"
      :button-id="assistantButtonId"
    >
      We can help
    </a-assistant-button>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import AAssistantButton from 'shared/AAssistantButton'
import { FIXED_LAYOUT } from 'enums/fixed-layout'

export default {
  name: 'AFooterService',
  components: { AAssistantButton },
  props: {
    assistantButtonId: propValidator([PROP_TYPES.STRING])
  },
  consts: { FIXED_LAYOUT }
}
</script>

<style lang="scss" scoped>
.footer-service__wrapper {
  display: flex;
  flex-direction: column;

  .footer-service__button {
    font-size: 14px;
  }

  @include mobile {
    align-items: center;
    padding-bottom: 20px;
  }
}

.footer-service__title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 21px;
  line-height: 22px;

  @include mobile {
    margin-bottom: 9px;
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.amp-layout__wrapper {
  .footer-service__wrapper {
    align-items: center;
    padding-bottom: 20px;
  }

  .footer-service__title {
    margin-bottom: 9px;
    font-size: 14px;
  }

  .footer-service__button.assistant.assistant__button {
    font-size: 14px;
  }
}
</style>
