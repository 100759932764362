<template>
  <div class="footer-socials__wrapper">
    <p class="footer-socials__title">Follow Us</p>
    <ul class="footer-socials__container">
      <li v-for="social in stayConnected" :key="social.Id" class="social-icon">
        <a-image
          v-if="$helper.isNotAmpPage($route.name)"
          :image="social.Logo"
          :aspect-ratio="aspectRatios.footerStayConnected"
          :url="prop('Url', social)"
          :open-in-new-tab="prop('OpenInNewTab', social)"
          :directives="prop('Directives', social)"
          object-fit="cover"
        />
        <a v-else :href="prop('Url', social)" target="_blank">
          <amp-img
            :alt="$helper.getAltTextForMediaCenterImage(social)"
            :src="pathOr('', ['Logo', 'URL'], social)"
            layout="fixed"
            width="25"
            height="25"
          ></amp-img>
        </a>
      </li>
    </ul>
    <a-visibility
      v-if="$helper.isNotAmpPage($route.name)"
      show
      :on="[$breakpoint.tablet, $breakpoint.desktopSm]"
    >
      <a-footer-service
        class="footer-socials__service"
        :assistant-button-id="$options.consts.ASSISTANT_BUTTON_ID.FOOTER_1"
      />
    </a-visibility>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pathOr, prop } from 'ramda'

import { aspectRatios } from 'enums/aspectRatios'
import AFooterService from '@/components/AFooter/AFooterContent/AFooterService'
import { ASSISTANT_BUTTON_ID } from 'shared/AAssistantButton/enums'

export default {
  name: 'AFooterSocials',
  components: { AFooterService },
  consts: {
    ASSISTANT_BUTTON_ID
  },
  data() {
    return { aspectRatios }
  },
  computed: {
    ...mapGetters({
      stayConnected: 'stayConnected'
    })
  },
  methods: { pathOr, prop }
}
</script>

<style lang="scss" scoped>
.footer-socials__wrapper {
  max-width: calc(100% + 30px);
}

.footer-socials__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  @include mobile {
    text-align: center;
    font-size: 14px;
  }
}

.social-icon {
  width: 25px;
}

.footer-socials__container {
  display: flex;
  justify-content: space-between;
  padding: 14px 0;

  @include tablet {
    margin: 0 auto;
    max-width: 160px;
    flex-wrap: wrap;
    justify-content: stretch;
  }

  @include mobile {
    max-width: none;
    margin: -20px 0 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  & .social-icon {
    margin-right: 35px;

    &:last-child {
      margin: 0;
    }

    @include tablet {
      &:nth-child(3n + 0) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    @include mobile {
      margin: 20px 15px 0;

      &:nth-child(3n + 0) {
        margin: 20px 15px 0;
      }

      &:last-child {
        margin: 20px 15px 0;
      }
    }
  }
}

.footer-socials__service {
  margin-top: 20px;
}
</style>

<style lang="scss">
.amp-layout__wrapper {
  .footer-socials__title {
    text-align: center;
  }

  .footer-socials__container {
    @include tablet {
      max-width: none;
    }

    & .social-icon {
      @include tablet {
        margin-right: 35px;
      }

      @include mobile {
        margin: 20px 15px 0;
      }
    }

    & .social-icon:nth-child(3n + 0) {
      @include tablet {
        margin: 0 35px 0 0;
      }

      @include mobile {
        margin: 20px 15px 0;
      }
    }
  }
}
</style>
