<template>
  <a-visibility hide :on="[$breakpoint.mobile]">
    <div class="footer-menu__block">
      <ul>
        <li
          v-for="item in menuItems"
          :key="`${prop('Name', item)}-${prop('Order', item)}`"
          class="footer-menu__element"
        >
          <a-link
            :to="prop('Url', item)"
            :open-in-new-tab="prop('OpenInNewTab', item)"
            :directive="prop('UrlDirectives', item)"
            class="footer-menu__link"
          >
            {{ prop('Name', item) }}
            <div
              class="footer-menu__logo-container"
              v-if="ifHasLogo(prop('Media', item))"
            >
              <a-image
                :image="item.Media"
                :aspect-ratio="aspectRatios.footerRightColumnLogo"
                :width="69"
                class="footer-menu__logo"
              />
            </div>
          </a-link>
        </li>
      </ul>
    </div>
  </a-visibility>
</template>

<script>
import { prop, pathOr } from 'ramda'

import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'AFooterMenuBlock',
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return { aspectRatios }
  },
  methods: {
    prop,
    pathOr,
    ifHasLogo(media) {
      return !!media
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-menu__block {
  height: 174px;
}

.footer-menu__element {
  display: flex;
}

.footer-menu__link {
  display: flex;

  font-size: 16px;
  line-height: 29px;
}

.footer-menu__logo-container {
  display: flex;
  align-items: center;
}

.footer-menu__logo {
  margin-left: 5px;
}
</style>
