<template>
  <!--Additional 'div' wrapper is needed to fix the issue with layout on AMP.-->
  <!--(without 'div' - all sibling content of this svg image is wrapped into <amp-img></amp-img> tag-->
  <div class="footer-logo__wrapper">
    <template v-if="$helper.isNotAmpPage($route.name)">
      <a-visibility show :on="targetBreakpoints">
        <img
          :src="$helper.getLinkToFmBucketFile('footer-logo.svg')"
          alt="Financial and Business News"
          title="Financial and Business News"
          class="footer-logo"
          loading="lazy"
        />
      </a-visibility>

      <a-visibility hide :on="targetBreakpoints">
        <img
          :src="$helper.getLinkToFmBucketFile('footer-logo-sm.svg')"
          alt="Financial and Business News"
          title="Financial and Business News"
          class="footer-logo-sm"
          loading="lazy"
        />
      </a-visibility>
    </template>
    <amp-img
      v-else
      alt="Financial and Business News"
      :src="$helper.getLinkToFmBucketFile('footer-logo.svg')"
      layout="fixed"
      width="100"
      height="42"
    ></amp-img>
  </div>
</template>

<script>
export default {
  name: 'AFooterLogo',
  computed: {
    targetBreakpoints() {
      return [
        this.$breakpoint.mobile,
        this.$breakpoint.desktopSm,
        this.$breakpoint.desktopLg,
        this.$breakpoint.desktopXl,
        this.$breakpoint.aboveDesktopXl
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-logo__wrapper {
  flex-shrink: 0;

  @include mobile {
    margin: 30px 0;
  }

  .footer-logo {
    width: 180px;
    height: 79px;

    @include desktop-sm {
      width: 120px;
      height: 53px;
    }

    @include mobile {
      display: block;
      width: 100px;
      margin: 0 auto;
    }
  }

  .footer-logo-sm {
    width: 39px;
    height: 59px;
  }
}
</style>
<style lang="scss">
.amp-layout__wrapper {
  .footer-logo__wrapper {
    margin: 30px 0;

    .footer-logo {
      display: block;
      width: 100px;
      margin: 0 auto;
    }
  }
}
</style>
