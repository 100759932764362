<template>
  <footer
    class="footer__container"
    v-observe-visibility="footerVisibilityOptions"
  >
    <div class="footer__content-container">
      <a-footer-content class="footer__content" />
    </div>
  </footer>
</template>

<script>
import { mapMutations } from 'vuex'

import AFooterContent from './AFooterContent'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import * as types from '@/store/mutation-types'

export default {
  name: 'a-footer',
  mixins: [serverCacheKey],
  components: { AFooterContent },
  data() {
    return {
      footerVisibilityOptions: {
        callback: this.setFooterVisibilityState,
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      }
    }
  },
  methods: {
    ...mapMutations({
      setFooterVisibilityState: `element-visibility/${types.SET_FOOTER_VISIBILITY_STATE}`
    })
  }
}
</script>

<style lang="scss" scoped>
.footer__container {
  width: 100%;
  height: 333px;
  background-color: $c--footer;
  padding-top: 60px;

  .footer__content-container {
    @include page-layout;
    @include page-paddings;

    padding-top: 0;
    padding-bottom: 0;
  }

  .footer__content {
    $grid-area: (
      $desktop-lg: #{1} / #{1} / #{2} / #{4}
    );
    @include setStyleByBreakpoint('grid-area', $grid-area);
  }

  @include desktop-sm {
    min-height: 278px;
    height: 345px;
    padding: 40px 5% 27px 5%;
  }

  @include tablet {
    height: 435px;
  }

  @include mobile {
    padding: 0 15px 18px;
    height: auto;
  }
}
</style>
<style lang="scss">
.amp-layout__wrapper {
  .footer__container {
    padding: 0 15px 30px;
    height: auto;

    .footer__content-container {
      @include amp-page-layout;
    }
  }
}
</style>
