<template>
  <div class="footer__terms">
    <p>
      Finance Magnates is a global B2B provider of multi-asset trading news,
      research and events with special focus on electronic trading, banking, and
      investing. Copyright © {{ currentYear }} "Finance Magnates CY Ltd." All
      rights reserved.<a-visibility
        show
        :on="[$breakpoint.tablet, $breakpoint.desktopSm]"
        ><br /></a-visibility
      >For more information, read our

      <a-link class="footer__terms-link" :to="linkToTermsOfUse">Terms, </a-link>
      <a-link class="footer__terms-link" :to="linkToCookies">Cookies </a-link>
      and
      <a-link class="footer__terms-link" :to="linkToPrivacyPolicy"
        >Privacy Notice
      </a-link>
      <a
        v-if="$helper.isNotAmpPage($route.name)"
        href="#"
        class="footer__manage-cookies-link"
        @click.prevent="$_oneTrust_toggleCookiePreferencesPopup"
        >Manage Cookies</a
      >
      <a-amp-manage-cookies-link v-else />
    </p>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
import { ROUTE_NAME } from 'enums/routes'
import { LINK_GENERATOR } from 'enums/links'

export default {
  name: 'AFooterTerms',
  components: {
    AAmpManageCookiesLink: () =>
      import('@/components/AFooter/AFooterContent/AAmpManageCookiesLink')
  },
  mixins: [mixins.urlGenerators, mixins.oneTrust],
  data() {
    return {
      ROUTE_NAME,
      currentYear: new Date().getFullYear(),
      linkToTermsOfUse: LINK_GENERATOR.TERMS_OF_USE(),
      linkToCookies: LINK_GENERATOR.COOKIES(),
      linkToPrivacyPolicy: LINK_GENERATOR.PRIVACY_POLICY()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__terms {
  font-size: 11px;
  line-height: 15px;
  margin-top: 40px;
  max-width: 795px;

  & * {
  }

  @include mobile {
    margin: 0;
    padding-top: 10px;
    border-top: 1px solid black;
  }
}

.footer__terms-link,
.footer__manage-cookies-link {
  text-decoration: underline;
}

.footer__manage-cookies-link {
  color: $c--purple;

  @include desktop-sm {
    margin-left: 20px;
  }

  @include mobile {
    width: 100%;
    display: block;
    margin: 15px 0 0;
    text-align: center;
  }
}
</style>
<style lang="scss">
.amp-layout__wrapper {
  .footer__terms {
    margin: 0;
    padding-top: 10px;
    border-top: 1px solid black;
  }
}
</style>
